import moment from 'moment';
import URL_ROUTE from '../constants/index';

class Api {
    async validateForm(token) {
        let data = {token}
        const query = await fetch(`${URL_ROUTE.VALIDATEFORM}`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async saveForm(token, methodrevoke, signature, picture, language, ip, getip) {
        let newDate = new Date()
        let data = {
            token, signature, methodrevoke, dateexpiration: moment(newDate).add(1, 'year').format('YYYY-MM-DD'), picture, language, ip, getip
        }
        const query = await fetch(`${URL_ROUTE.SAVEFORM}`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

}

export default new Api();
